
import { defineComponent } from '@vue/runtime-core'
import { DefaultTableExposed } from 'apptimizm-ui'
import { ProjectMeta } from '../projects/types'
import axios from '@/axios'
import { Ref, computed, onMounted, ref } from 'vue'
import Table from './table.vue'
import { store, mutations } from '@/store/'

export default defineComponent({
  setup () {
    const projecttMeta = new ProjectMeta()
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const isProjectSelected = computed(() => !!store.currentProject.id)

    const tableFilter = {
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id

        return res
      }
    }

    onMounted(async () => {
      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = projecttMeta.load((await axios.get(`${projecttMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }
    })
    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Терминалы</h1>
          <div class="page-top-panel-row mt-5">
          </div>
        </div>
        <div class="layout">
          {store.currentProject.id ? <Table table={table} tableFilter={tableFilter}/> : null}
        </div>
      </div>
    )
  }
})
