
import { DefaultTable } from 'apptimizm-ui'
import axios from '@/axios'
import { DefaultTableExposed, TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import { Ref } from 'vue'
import { Terminal, TerminalMeta } from './types'
import moment from 'moment'

interface TableFilter {
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
}

export default ({ table, tableFilter }: ITableProps) => {
  const terminalMeta = new TerminalMeta()
  const headers: TableHeader[] = [
    { name: '№ терминала', sort: 'number', minWidth: '400px' },
    { name: 'IP терминала', sort: 'ipAddress', minWidth: '180px' },
    { name: 'Время начала коннекта ', sort: 'lastConnect', minWidth: '260px' },
    { name: 'Загрузка БД', sort: 'dbLoading', minWidth: '260px' }
  ]

  const line = (item: Terminal) => {
    return (
      <tr>
        <td>{item.number}</td>
        <td>{item.ipAddress}</td>
        <td>{item.lastConnect ? moment(item.lastConnect).locale('ru').format('DD MMMM YYYY HH:MM') : 'Нет данных'}</td>
        <td class={item.dbLoading ? 'db active' : 'db'}>{item.dbLoading ? 'Загружена' : 'Не загружена'}</td>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={terminalMeta.endpoint}
        itemConverter={(v: any) => terminalMeta.load(v)}
        defaultFilter={tableFilter.tableParams}
        ref={table}
        line={line}
        headers={headers}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        perPage={20}
      />
    </div>
  )
}
